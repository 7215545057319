import * as React from 'react'
import styled from 'styled-components'
import PageHeader from '../components/PageHeader'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import Notes from '../components/Notes'
import Table from '../components/Table'
//import InsufficientData from '../components/InsufficientData'
import ImageExport from '../components/ImageExport'
import Footer from '../components/Footer'
import {
  WHO_LOCATION_OPTIONS_ALT,
  INC_LOCATION_OPTIONS_ALT,
  UNI_LOCATION_OPTIONS_ALT,
  VACCINE_LABELS_API
} from '../utils/const'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'
import { group } from 'd3-array'

const TableContainer = styled.div`
  margin: 1rem 0 0.5rem 0;
`
const ChartNotes = styled.div`
  opacity: 0.9;
  font-size: 1.15rem;
`
const Sp61Page = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { sectionId } = pageContext
  const { content, footerContent } = data
  const vaccines = data.vaccines.nodes
  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')

  //const isGbl = reg === 'GBL' || !reg
  const regionLabel = t('global')
  //**CURRENTLY ONLY GLOBAL DATA FOR SP6.1 */
  // const allYears = []
  // const wrangleData = (data) => {
  //   const allData = data.map((item) => {
  //     return Object.entries(item)
  //       .map(([key, value]) => {
  //         if (key !== 'vaccine' && key !== 'notes' && value !== null) {
  //           const year = key.slice(-4)
  //           allYears.push(year)

  //           return {
  //             vaccine: item.vaccine,
  //             year,
  //             note: item.notes,
  //             status: value
  //           }
  //         } else return null
  //       })
  //       .filter((item) => item)
  //   })

  //   const years = Array.from(new Set(allYears))
  //   const formattedData = allData.map((arr) => {
  //     return years
  //       .map((year) => {
  //         return arr
  //           .filter((d) => d.year === year)
  //           .reduce((acc, d) => {
  //             acc['year'] = d.year
  //             acc['note'] = d.note
  //             acc['status'] = d.status
  //             acc['vaccine'] = d.vaccine
  //             return acc
  //           }, {})
  //       })
  //       .map((item) => {
  //         return {
  //           vaccine: { name: t(item.vaccine), note: item.note },
  //           [item.year]: item.status
  //         }
  //       })
  //       .filter((d) => d.vaccine)
  //       .reduce((acc, d) => {
  //         return { ...acc, ...d }
  //       }, {})
  //   })

  //   return formattedData
  // }
  //const sp61DataOld = wrangleData(vaccines)
  const NOTE_LOOKUP = {
    BCG: 1,
    MMR: 2,
    PNEUMO_CONJ: 2,
    PNEUMO_PS: 2,
    TD: 2,
    DTAPHIBHEPIPV: 2
  }

  const BASELINE_YEAR = '2019-2020'
  let yrs = []
  const wrangleData = (data) => {
    const dataMap = group(data, (d) => d.DIMENSION2)

    const dataByAntigen = Array.from(dataMap.values())
    return dataByAntigen
      .map((p) => {
        return p.reduce((acc, d) => {
          if (d.YEAR_RANGE !== BASELINE_YEAR) yrs.push(d.YEAR_RANGE ?? d.YEAR)
          acc[
            d.YEAR_RANGE === BASELINE_YEAR ? 'Baseline' : d.YEAR_RANGE ?? d.YEAR
          ] = d.VALUE_STR
          acc['vaccine'] = {
            name: t(VACCINE_LABELS_API[d.DIMENSION2]),
            note: NOTE_LOOKUP[d.DIMENSION2]
          }
          return acc
        }, {})
      })
      .sort((a, b) => (a.vaccine.name > b.vaccine.name ? 1 : -1))
  }
  const sp61Data = wrangleData(vaccines)
  const tableData = React.useMemo(() => sp61Data, [sp61Data])

  const years = Array.from(new Set(yrs)).sort((a, b) => (a > b ? 1 : -1))

  const yearHeaders = years.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })
  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: t('vaccine'),
        accessor: 'vaccine' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (2019/2020)`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])
  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS_ALT}
          incOptions={INC_LOCATION_OPTIONS_ALT}
          uniOptions={UNI_LOCATION_OPTIONS_ALT}
          sectionId={sectionId}
        />
        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>

        <>
          <ImageExport
            title={`${content.chartTitle}—${regionLabel}`}
            dataSource={content.dataSourceNotesRt}
          >
            <TableContainer>
              <Table
                data={tableData}
                columns={columns}
                noBoldTopRow={true}
                sp={'61'}
              />
            </TableContainer>
            <ChartNotes
              dangerouslySetInnerHTML={{ __html: content.chartNotesRt }}
            />
            <br />
          </ImageExport>
          <TableContainer>
            <Table
              data={tableData}
              columns={columns}
              noBoldTopRow={true}
              sp={'61'}
            />
          </TableContainer>
          <ChartNotes
            dangerouslySetInnerHTML={{ __html: content.chartNotesRt }}
          />
        </>
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Sp61Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleSp61CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      chartNotesRt
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    vaccines: allRegions(
      filter: { IA2030_IND: { eq: "SP6.1" }, CATEGORY: { eq: "GLOBAL" } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        YEAR_RANGE
        INDCODE
        DIMENSION2
        VALUE_STR
      }
    }
  }
`
// vaccines: allGoogleSp61Sheet {
//   nodes {
//     vaccine
//     status20192020
//     notes
//     status2021
//     notes2021
//     status2022
//     notes2022
//     status2022
//     notes2023
//     status2024
//     notes2024
//     status2025
//     notes2025
//     status2026
//     notes2026
//     status2027
//     notes2027
//     status2028
//     notes2028
//     status2029
//     notes2029
//     status2030
//     notes2030
//   }
// }
